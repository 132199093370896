import {Component} from '@angular/core';

@Component({
    selector: 'app-button-loading',
    standalone: true,
    imports: [],
    templateUrl: './button-loading.component.html',
    styleUrl: './button-loading.component.scss',
})
export class ButtonLoadingComponent {}
