<!-- Main modal -->
<div class="relative p-4 w-full max-w-md max-h-full mx-auto">
    <app-mt-modal-close-button></app-mt-modal-close-button>
    <!-- Modal content -->
    <div class="relative flex items-center justify-center flex-col bg-white rounded-lg shadow dark:bg-gray-700">
        <!-- Modal header -->
        <div
            *ngIf="!showFollow"
            class="flex items-center justify-center px-4 py-8 md:pt-10 md:pb-8 rounded-t dark:border-gray-600"
        >
            <h3 class="text-xl font-semibold text-black dark:text-white">{{ 'AuthOptions_Welcome!' | transloco }}</h3>
        </div>
        <div
            *ngIf="showFollow"
            class="flex flex-col items-center justify-center px-4 py-8 md:pt-10 md:pb-8 rounded-t dark:border-gray-600 gap-4 select-none pointer-events-none"
        >
            <div class="flex flex-row gap-2 items-center justify-center">
                <img src="{{ userDetail.photo }}" alt="user" class="w-[50px] h-[50px] rounded-full" />
                <h3 class="text-lg font-semibold text-center text-black dark:text-white">{{ userDetail.fullName }}</h3>
            </div>
            <h3 class="text-lg font-semibold text-black dark:text-white">
                {{ constantService.i18n_constants.Follow_Register_Steps | transloco }}
            </h3>
        </div>
        <!-- Modal body -->
        <div class="px-4 pb-4 md:px-8 md:pb-8">
            <div class="flex flex-col w-full items-center justify-center">
                <app-mt-login-button (fbLogin)="loginWithFacebook()" class="w-full"></app-mt-login-button>
                <p class="mt-8 font-normal text-black text-xs dark:text-white">
                    {{ constantService.i18n_constants.Not_Registered_Yet | transloco }}
                    <button class="text-primary-600 cursor-pointer" (click)="register()">
                        {{ constantService.i18n_constants.Sign_Up | transloco }}
                    </button>
                </p>
            </div>
        </div>

        <!-- Modal footer -->
        <div
            class="flex w-1/2 items-center justify-center text-center pb-8 font-normal text-xs text-[#7E8594] dark:text-neutral-200"
        >
            <p>
                <!--                #By signing up you are accepting our <a class="text-[#2663F0]">Privacy Policy</a> and-->
                <!--                <a class="text-[#2663F0]">Terms of Use</a>#-->
                {{ 'GenderSelection_Accept_Terms' | transloco }}
            </p>
        </div>
    </div>
</div>
