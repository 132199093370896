import {Component, inject} from '@angular/core';
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {ModalCloseButtonComponent} from '@core/components/ui-components/modal-close-button/modal-close-button.component';
import {UserResponse} from '@core/models/user/user-response';
import {AuthService} from '@core/services';
import {UserRegisterRequest} from '../../../core/models/user/user-register-request';
import {CheckEmailResponse} from '@core/models/auth/check-email-response';
import {Router} from '@angular/router';
import {ModalService} from '@core/services/modal/modal.service';
import {CommonModule} from '@angular/common';
import {CommonError} from '@core/models/common/common-error';
import {AlertBoxComponent} from '@shared/components/alert-box/alert-box.component';
import {translate, TranslocoPipe} from '@ngneat/transloco';
import {MailLoginModalComponent} from '../mail-login-modal/mail-login-modal.component';
import {ConstantService} from '@core/services/common/constant.service';
import {UserService} from '@core/services/user/user.service';
import {storage} from '@core/utils';
import {ToastService} from '@core/services/ui/toast/toast.service';
import {ToastOptions} from '@shared/components/toast/ToastOptions';
import {i18nConstants} from '@core/constants/i18n-constants';
import validateEmail from '@core/utils/helpers/email-validator';
import {passwordValidator} from '@core/utils/helpers/password-validator';
import {ButtonLoadingComponent} from '@core/components/ui-components/button-loading/button-loading.component';
import {ConversionService} from '@core/services/conversion/conversion.service';
import {getCookieValue} from '@core/utils/helpers/cookie-helper';

@Component({
    selector: 'app-register',
    standalone: true,
    imports: [
        ModalCloseButtonComponent,
        ReactiveFormsModule,
        CommonModule,
        AlertBoxComponent,
        TranslocoPipe,
        ButtonLoadingComponent,
    ],
    templateUrl: './register.component.html',
    styleUrl: './register.component.scss',
})
export class RegisterComponent {
    authService = inject(AuthService);
    userService = inject(UserService);
    router = inject(Router);
    constantService = inject(ConstantService);
    conversionService = inject(ConversionService);
    toastService = inject(ToastService);
    error: CommonError | undefined;

    registerForm = new FormGroup({
        email: new FormControl<string>('', [Validators.required, Validators.email]),
        password: new FormControl<string>(''),
    });

    isLoading = false;

    registerData!: UserRegisterRequest;
    constructor(private modalService: ModalService) {}

    onSubmit() {
        this.isLoading = true;
        const errorMessage: ToastOptions = {
            message: translate(i18nConstants.EmailEntry_Provide_Valid_Email),
            type: 'error',
            duration: 5000,
            position: 'bottom-center',
            closeable: true,
        };

        const passwordError: ToastOptions = {
            message: translate(i18nConstants.PasswordEntry_Provide_Valid_Password),
            type: 'error',
            duration: 5000,
            position: 'bottom-center',
            closeable: true,
        };
        if (this.registerForm.invalid) {
            this.toastService.openToast(errorMessage);
            this.isLoading = false;
            return;
        }
        if (!validateEmail(this.registerForm.value.email!)) {
            this.toastService.openToast(errorMessage);
            this.isLoading = false;
            return;
        }
        if (!passwordValidator(this.registerForm.value.password!)) {
            this.toastService.openToast(passwordError);
            this.isLoading = false;
            return;
        }
        this.authService.checkEmail(this.registerForm.value.email!).subscribe((data: CheckEmailResponse) => {
            if (data.success) {
                if (data.isExist) {
                    this.error = {
                        title: 'ERROR',
                        description: 'EMAIL_ALREADY_EXIST',
                    };
                    this.isLoading = false;
                    //redirect to login module
                } else {
                    this.registerData = {
                        email: this.registerForm.value.email!,
                        password: this.registerForm.value.password!,
                        countryCode: Intl.DateTimeFormat().resolvedOptions().locale,
                        langCode: navigator.language,
                        deviceType: 2,
                        uuid: this.authService.appSession$.getValue()!.uuid,
                    };

                    this.authService.register(this.registerData).subscribe(
                        (data: UserResponse) => {
                            if (data.success) {
                                this.isLoading = false;
                                this.authService.setSession(this.authService.appSession$.getValue()!.uuid, false, data);
                                const userToFollow = this.userService.userToFollow;
                                const adParams = localStorage.getItem('ad_params');
                                if (adParams) {
                                    const adParamsParsed = adParams.replaceAll('%20', ' ');
                                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                    const adParamsObject = JSON.parse(adParamsParsed);
                                    if (
                                        adParamsObject &&
                                        (adParamsObject.af_sub1 != '' ||
                                            adParamsObject.af_sub3 != '' ||
                                            adParamsObject.af_sub4 != '')
                                    ) {
                                        if (adParamsObject.c) adParamsObject.campaign = adParamsObject.c;
                                        adParamsObject.click_time = new Date().toISOString();
                                        adParamsObject.fbp = getCookieValue('_fbp');
                                        adParamsObject.fbc = getCookieValue('_fbc');
                                        const conversionData = JSON.stringify(adParamsObject);
                                        this.conversionService.sendConversionData(conversionData).subscribe(res => {
                                            localStorage.removeItem('ad_params');
                                            if (userToFollow) {
                                                this.userService
                                                    .followUser(userToFollow, {fullName: '', photo: ''})
                                                    .subscribe(res => {
                                                        this.userService.userToFollow$.next(null);
                                                        storage.removeItem('userToFollow');
                                                        window.location.reload();
                                                    });
                                            } else {
                                                window.location.reload();
                                            }
                                        });
                                    } else {
                                        if (userToFollow) {
                                            this.userService
                                                .followUser(userToFollow, {fullName: '', photo: ''})
                                                .subscribe(res => {
                                                    this.userService.userToFollow$.next(null);
                                                    storage.removeItem('userToFollow');
                                                    window.location.reload();
                                                });
                                        } else {
                                            window.location.reload();
                                        }
                                    }
                                } else {
                                    if (userToFollow) {
                                        this.userService
                                            .followUser(userToFollow, {fullName: '', photo: ''})
                                            .subscribe(res => {
                                                this.userService.userToFollow$.next(null);
                                                storage.removeItem('userToFollow');
                                                window.location.reload();
                                            });
                                    } else {
                                        window.location.reload();
                                    }
                                }
                            } else {
                                //alert something went wrong
                            }
                        },
                        error1 => {
                            this.toastService.openToast(errorMessage);
                            this.isLoading = false;
                        },
                    );
                }
            }
        });
    }

    redirectToLogin() {
        this.modalService.closeAndOpen(MailLoginModalComponent);
    }
}
