import {FacebookLoginProvider, SocialAuthService, SocialUser} from '@abacritt/angularx-social-login';
import {Component, inject} from '@angular/core';
import {LoginButtonComponent} from '@core/components/ui-components/login-button/login-button.component';
import {ModalCloseButtonComponent} from '@core/components/ui-components/modal-close-button/modal-close-button.component';
import {AuthService} from '@core/services';
import {UserRegisterRequest} from '../../../core/models/user/user-register-request';
import {UserResponse} from '@core/models/user/user-response';
import {Router} from '@angular/router';
import {ModalService} from '@core/services/modal/modal.service';
import {RegisterComponent} from '@pages/auth/register/register.component';
import {TranslocoPipe} from '@ngneat/transloco';
import {ConstantService} from '@core/services/common/constant.service';
import {NgIf} from '@angular/common';
import {UserService} from '@core/services/user/user.service';
import {storage} from '@core/utils';

@Component({
    selector: 'app-login',
    standalone: true,
    imports: [LoginButtonComponent, ModalCloseButtonComponent, TranslocoPipe, NgIf],
    templateUrl: './login.component.html',
    styleUrl: './login.component.scss',
})
export class LoginComponent {
    socialAuthService = inject(SocialAuthService);
    authService = inject(AuthService);
    userService = inject(UserService);
    router = inject(Router);
    constantService = inject(ConstantService);
    buttonWidth: number;

    showFollow = false;
    userDetail: {fullName: string; photo: string} = {fullName: '', photo: ''};

    registerData!: UserRegisterRequest;

    constructor(private modalService: ModalService) {
        this.buttonWidth = 100;

        if (this.authService.appSession$.getValue()?.isSkipped) {
            this.socialAuthService.authState.subscribe((user: SocialUser): void => {
                if (user) {
                    if (user.provider == 'GOOGLE') {
                        this.registerData = {
                            email: user.email,
                            fullName: user.name,
                            accountId: user.id,
                            countryCode: Intl.DateTimeFormat().resolvedOptions().locale,
                            langCode: navigator.language,
                            uuid: this.authService.appSession$.getValue()!.uuid,
                        };

                        this.authService.register(this.registerData).subscribe((data: UserResponse) => {
                            this.authService.setSession(this.authService.appSession$.getValue()!.uuid, false, data);
                            const userToFollow = this.userService.userToFollow;
                            if (userToFollow) {
                                this.userService.followUser(userToFollow, {fullName: '', photo: ''}).subscribe(res => {
                                    this.userService.userToFollow$.next(null);
                                    storage.removeItem('userToFollow');

                                    // window.location.reload();
                                });
                            } else {
                                // window.location.reload();
                            }
                        });
                    }
                }
            });
        }
    }

    loginWithFacebook() {
        this.socialAuthService
            .signIn(FacebookLoginProvider.PROVIDER_ID)
            .then((user: SocialUser) => {
                if (user) {
                    this.registerData = {
                        email: user.email,
                        fullName: user.name,
                        accountId: user.id,
                        countryCode: Intl.DateTimeFormat().resolvedOptions().locale,
                        langCode: navigator.language,
                        uuid: this.authService.appSession$.getValue()!.uuid,
                        isFacebook: 1,
                    };

                    this.authService.register(this.registerData).subscribe((data: UserResponse) => {
                        this.authService.setSession(this.authService.appSession$.getValue()!.uuid, false, data);
                        const userToFollow = this.userService.userToFollow;
                        if (userToFollow) {
                            this.userService.followUser(userToFollow, {fullName: '', photo: ''}).subscribe(res => {
                                this.userService.userToFollow$.next(null);
                                storage.removeItem('userToFollow');

                                // window.location.reload();
                            });
                        } else {
                            // window.location.reload();
                        }
                    });
                }
            })
            .catch((error: any) => {
                console.log('FB LOGIN ERROR: ', error);
            });
    }

    register() {
        this.modalService.closeAndOpen(RegisterComponent);
    }
}
