<div class="relative p-4 w-full max-w-md max-h-full mx-auto">
    <app-mt-modal-close-button></app-mt-modal-close-button>
    <!-- Modal content -->
    <div class="relative flex items-center justify-center flex-col bg-white rounded-lg shadow dark:bg-gray-700">
        <!-- Modal header -->
        <div
            class="flex flex-col items-center justify-center px-4 py-8 md:pt-10 md:pb-8 rounded-t dark:border-gray-600"
        >
            <h3 class="text-lg font-semibold text-black dark:text-white">
                {{ constantService.i18n_constants.Login_Login | transloco }}
            </h3>
        </div>
        <!-- Modal body -->
        <div class="px-4 pb-4 md:px-8 md:pb-8 w-full">
            <div class="flex flex-col w-full items-center justify-center">
                <div class="mb-8 w-full">
                    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                        <label for="username" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{{
                            constantService.i18n_constants.Login_Email | transloco
                        }}</label>
                        <input
                            [autofocus]="true"
                            type="email"
                            id="username"
                            formControlName="username"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-secondary-500 focus:border-secondary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-secondary-500 dark:focus:border-secondary-500"
                            placeholder=""
                            required
                        />
                        <label
                            for="password"
                            class="pt-4 block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                            >{{ constantService.i18n_constants.Login_Password | transloco }}</label
                        >
                        <input
                            type="password"
                            id="password"
                            formControlName="password"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-secondary-500 focus:border-secondary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-secondary-500 dark:focus:border-secondary-500"
                            placeholder="********"
                            required
                        />
                        <div class="flex flex-row-reverse pt-4">
                            <button
                                type="button"
                                (click)="modalService.closeAndOpen(ForgotPasswordModalComponent)"
                                class="text-sm font-medium text-primary-500 dark:text-blue-400 cursor-pointer"
                            >
                                {{ constantService.i18n_constants.Login_Forgot_Password | transloco }}
                            </button>
                        </div>
                        <div class="w-full mt-8">
                            <button
                                class="w-full max-w-full py-2.5 bg-black text-white text-sm font-medium rounded-lg cursor-pointer hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 dark:bg-white dark:text-black dark:hover:bg-neutral-200 dark:focus:ring-blue-500 dark:focus:ring-offset-gray-900 dark:focus:ring-offset-2"
                                type="submit"
                                [disabled]="!loginForm.valid"
                            >
                                <p *ngIf="!this.isLoggingIn">
                                    {{ constantService.i18n_constants.Login_Login | transloco }}
                                </p>
                                <app-button-loading *ngIf="this.isLoggingIn"></app-button-loading>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div *ngIf="error" class="px-4 pb-4 md:px-8 md:pb-8 w-full">
            <div class="flex flex-col w-full items-center justify-center mb-4">
                <app-alert-box class="w-full" [description]="error.description | transloco"></app-alert-box>
            </div>
        </div>
    </div>
</div>
