<div class="relative p-4 min-w-80 max-h-full mx-auto">
    <app-mt-modal-close-button></app-mt-modal-close-button>
    <!-- Modal content -->
    <div class="relative flex items-center justify-center flex-col bg-white rounded-lg shadow dark:bg-gray-700">
        <!-- Modal header -->
        <div
            class="flex flex-col items-center justify-center px-4 py-8 md:pt-10 md:pb-8 rounded-t dark:border-gray-600"
        >
            <div class="flex flex-row justify-center items-center">
                <img src="assets/images/shared/login/check.svg" alt="Email Sent" class="w-5 h-5" />
                <h3 class="pl-1 text-lg font-semibold text-black dark:text-white">
                    {{ i18nConstants.ForgotPassword_Mail_Sent | transloco }}
                </h3>
            </div>
        </div>
        <!-- Modal body -->
        <div class="px-4 pb-4 md:px-8 md:pb-8 w-full">
            <div class="flex flex-col w-full items-center justify-center text-black dark:text-white">
                <p>{{ i18nConstants.ForgotPassword_Check_MailBox | transloco }}</p>
            </div>
        </div>
    </div>
</div>
