import {HttpErrorResponse, HttpStatusCode} from '@angular/common/http';
import {Component, inject} from '@angular/core';
import {FormControl, FormGroup, ReactiveFormsModule} from '@angular/forms';
import {Router} from '@angular/router';
import {ModalCloseButtonComponent} from '@core/components/ui-components/modal-close-button/modal-close-button.component';
import {Login} from '@core/models/auth/login';
import {UserResponse} from '@core/models/user/user-response';
import {AuthService} from '@core/services';
import {ModalService} from '@core/services/modal/modal.service';
import {ForgotPasswordModalComponent} from '@pages/auth/forgot-password-modal/forgot-password-modal.component';
import {AlertBoxComponent} from '@shared/components/alert-box/alert-box.component';
import {CommonError} from '@core/models/common/common-error';
import {CommonModule} from '@angular/common';
import {TranslocoPipe} from '@ngneat/transloco';
import {ConstantService} from '@core/services/common/constant.service';
import {UserService} from '@core/services/user/user.service';
import {storage} from '@core/utils';
import {ButtonLoadingComponent} from '@core/components/ui-components/button-loading/button-loading.component';

@Component({
    selector: 'app-mail-login-modal',
    standalone: true,
    imports: [
        ModalCloseButtonComponent,
        ReactiveFormsModule,
        AlertBoxComponent,
        CommonModule,
        TranslocoPipe,
        ButtonLoadingComponent,
    ],
    templateUrl: './mail-login-modal.component.html',
    styleUrl: './mail-login-modal.component.scss',
})
export class MailLoginModalComponent {
    authService = inject(AuthService);
    router = inject(Router);
    modalService = inject(ModalService);

    userService = inject(UserService);

    error: CommonError | undefined;
    constantService = inject(ConstantService);

    isLoggingIn = false;

    constructor() {}
    loginForm = new FormGroup({
        username: new FormControl<string>('', {nonNullable: true}),
        password: new FormControl<string>('', {nonNullable: true}),
    });

    loginData!: Login;

    onSubmit() {
        //this.elRef.nativeElement.style.display = 'none';
        this.isLoggingIn = true;
        this.loginData = {
            username: this.loginForm.value.username!,
            password: this.loginForm.value.password!,
        };

        this.authService.login(this.loginData).subscribe(
            (data: UserResponse) => {
                if (data.success) {
                    this.authService.setSession(this.authService.appSession$.getValue()!.uuid, false, data);
                    const userToFollow = this.userService.userToFollow;
                    if (userToFollow) {
                        this.userService.followUser(userToFollow, {fullName: '', photo: ''}).subscribe(res => {
                            this.userService.userToFollow$.next(null);
                            storage.removeItem('userToFollow');
                            this.isLoggingIn = false;
                            this.modalService.close();
                            // window.location.reload();
                        });
                    } else {
                        this.isLoggingIn = false;
                        this.modalService.close();
                        // window.location.reload();
                    }
                    //this.router.navigateByUrl('live');
                } else {
                    //alert something went wrong
                    this.isLoggingIn = false;
                    this.modalService.close();
                }
            },
            (error: HttpErrorResponse) => {
                if (error.status === HttpStatusCode.BadRequest) {
                    this.isLoggingIn = false;
                    this.error = {
                        title: 'ERROR',
                        description: error.error.details.message,
                    };
                    //console.log(error.error);
                    //this.modalService.open(RegisterComponent);
                }
            },
        );
    }

    protected readonly ForgotPasswordModalComponent = ForgotPasswordModalComponent;
}
