import {ApplicationRef, ComponentRef, createComponent, EnvironmentInjector, Injectable, Type} from '@angular/core';
import {ModalComponent} from '@core/components/modal/modal.component';
import {Subject} from 'rxjs';
import {Options, SubjectModal} from '@core/services/modal/modal-options';

@Injectable({
    providedIn: 'root',
})
export class ModalService {
    newModalComponent!: ComponentRef<ModalComponent>;
    newComponent!: ComponentRef<any>;
    private modalSubject!: Subject<any>;

    modalInstances: ModalComponent[] = [];

    private modalSubjects: SubjectModal[] = [];

    options!: Options | undefined;

    constructor(
        private appRef: ApplicationRef,
        private injector: EnvironmentInjector,
    ) {}

    open<C>(component: Type<C>, options?: Options) {
        this.options = options;
        this.modalSubject = new Subject();
        this.openWithComponent(component, options);
        return this.modalSubject;
    }

    private openWithComponent(component: Type<unknown>, options?: Options) {
        this.newComponent = createComponent(component, {environmentInjector: this.injector});

        this.newModalComponent = createComponent(ModalComponent, {
            environmentInjector: this.injector,
            projectableNodes: [[this.newComponent.location.nativeElement]],
        });
        this.instantiateProps(options?.data);
        this.modalSubjects.push({subject: this.modalSubject});

        document.body.appendChild(this.newModalComponent.location.nativeElement);

        //Attach views to the changeDetection cycle
        this.appRef.attachView(this.newComponent.hostView);
        this.appRef.attachView(this.newModalComponent.hostView);
    }

    private instantiateProps(data: Options['data'] = {}) {
        for (const key of Object.keys(data)) {
            this.newComponent.instance[key] = data[key];
        }
    }

    closeAndOpen<C>(component: Type<C>, options?: Options): void {
        this.close();
        this.openWithComponent(component, options);
    }

    close(data?: unknown) {
        this.modalInstances.pop()?.close();

        if (this.modalSubjects.length === 0) return;

        const currentSubject = this.modalSubjects.pop() as SubjectModal;
        if (currentSubject.subject !== undefined) {
            currentSubject.subject.next(data);
            currentSubject.subject.complete();
        }
    }
}
