import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-alert-box',
  standalone: true,
  imports: [NgIf],
  templateUrl: './alert-box.component.html',
  styleUrl: './alert-box.component.scss'
})
export class AlertBoxComponent {
    @Input() title!:string;
    @Input() description!:string;

}
