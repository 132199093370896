<div class="relative p-4 w-full max-w-md max-h-full mx-auto">
    <app-mt-modal-close-button></app-mt-modal-close-button>
    <!-- Modal content -->
    <div class="relative flex items-center justify-center flex-col bg-white rounded-lg shadow dark:bg-gray-700">
        <!-- Modal header -->
        <div
            class="flex flex-col items-center justify-center px-4 py-8 md:pt-10 md:pb-8 rounded-t dark:border-gray-600"
        >
            <h3 class="text-lg font-semibold text-black dark:text-white">Scan the code to login</h3>
            <p class="py-2 px-4 text-center font-normal text-xs dark:text-neutral-300">
                Scan the code with your camera and login with your existing account
            </p>
        </div>
        <!-- Modal body -->
        <div class="px-4 pb-4 md:px-8 md:pb-8">
            <div class="flex flex-col w-full items-center justify-center">
                <div *ngIf="qrData" class="flex items-center justify-center bg-[#F8FAFF] rounded-lg dark:bg-[#4E5A6C]">
                    <qrcode [qrdata]="qrData.hash" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
                    <!--<img src="assets/images/shared/login/qr-code.svg" alt="QR Code Login" />-->
                </div>
            </div>
        </div>
    </div>
</div>
