import {CommonModule} from '@angular/common';
import {Component, inject} from '@angular/core';
import {Router} from '@angular/router';
import {LoginButtonComponent} from '@core/components/ui-components/login-button/login-button.component';
import {ModalCloseButtonComponent} from '@core/components/ui-components/modal-close-button/modal-close-button.component';
import {QrCodeResponse} from '@core/models/auth/qr-code-response';
import {QrLoginSocket} from '@core/models/chat/socket/qr-login-socket';
import {UserResponse} from '@core/models/user/user-response';
import {AuthService} from '@core/services';
import {ChatSocketService} from '@core/services/chat/chat-socket.service';
import {QRCodeModule} from 'angularx-qrcode';

@Component({
    selector: 'app-qr-login',
    standalone: true,
    imports: [LoginButtonComponent, ModalCloseButtonComponent, QRCodeModule, CommonModule],
    templateUrl: './qr-login.component.html',
    styleUrl: './qr-login.component.scss',
})
export class QrLoginComponent {
    socketService = inject(ChatSocketService);
    authService = inject(AuthService);
    router = inject(Router);

    qrData!: QrCodeResponse;
    constructor() {
        this.socketService.isConnected$.subscribe((isconnected: boolean) => {
            if (isconnected) {
                this.authService
                    .generateQrCode(this.socketService.socketId, this.authService.appSession$.getValue()!.uuid)
                    .subscribe((res: QrCodeResponse) => {
                        this.qrData = res;
                    });

                    this.socketService.onQrLogin().subscribe((data: string) => {
                        const resLogin: QrLoginSocket = JSON.parse(data);
                        const userResponse: UserResponse = {
                            token: resLogin.token,
                            refreshToken: resLogin.refreshToken,
                            user: resLogin.user,
                            message: '',
                            success: true,
                            isSaved: true,
                        };
                        this.authService.setSession(resLogin.uuid, false, userResponse);
                        this.router.navigateByUrl('live');
                    });
                //response gelen expireAt bilgisine göre arkaplanda kontrol edilip bitiminde tekrar istek atılmalı
                this.socketService.isConnected$.unsubscribe();
            }
        });


    }

    //this.socketService.disconnectSocket();
}
