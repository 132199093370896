import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    HostBinding,
    HostListener,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import {ModalService} from '@core/services/modal/modal.service';
import {ThemeService} from '@core/services';
import {NgIf} from '@angular/common';
import {changeModalPosition, ModalPositionIndicator, Options} from '@core/services/modal/modal-options';
import {fromEvent, Observable} from 'rxjs';
import {TranslocoDirective} from '@ngneat/transloco';

@Component({
    selector: 'app-modal',
    standalone: true,
    imports: [NgIf, TranslocoDirective],
    templateUrl: './modal.component.html',
    styleUrl: './modal.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class ModalComponent implements OnInit, AfterViewInit {
    @ViewChild('modal', {static: false}) modal!: ElementRef<HTMLDivElement>;
    @ViewChild('overlay', {static: false}) overlay!: ElementRef<HTMLDivElement>;
    modalAnimationEnd!: Observable<Event>;
    modalLeaveAnimation = '';
    options!: Options | undefined;

    overlayClosed = false;
    modalClosed = false;

    constructor(
        private modalService: ModalService,
        private element: ElementRef,
        private themeService: ThemeService,
    ) {}

    ngOnInit() {
        this.options = this.modalService.options;
        this.modalService.modalInstances.push(this);
    }

    ngAfterViewInit() {
        this.addOptionsAndAnimations();
    }

    addOptionsAndAnimations() {
        //POSITION
        const modalPositions: ModalPositionIndicator = changeModalPosition(this.options?.position);
        this.changeModalPosition(modalPositions.left, modalPositions.top);

        //ANIMATIONS
        this.modalLeaveAnimation = this.options?.modal?.leave || 'scale-up-flash 0.3s ease-in-out';
        if (this.modal) {
            this.modal.nativeElement.style.animation =
                this.options?.modal?.enter || 'scale-down-flash 0.3s ease-in-out';

            this.modalAnimationEnd = fromEvent(this.modal.nativeElement, 'animationend');
        }

        if (this.options?.backdrop) {
            if (this.overlay) {
                this.overlay.nativeElement.classList.add('backdrop-blur-lg');
            }
        }
    }

    changeModalPosition(left: number, top: number) {
        document.documentElement.style.setProperty('--modal-left', `${left}%`);
        document.documentElement.style.setProperty('--modal-top', `${top}%`);
    }

    @HostListener('document:keydown.escape')
    onEscape() {
        this.modalService.close();
    }

    onClose() {
        this.modalService.close();
    }

    close() {
        this.modal.nativeElement.style.animation = this.modalLeaveAnimation;

        if (!this.modalLeaveAnimation) {
            this.element.nativeElement.remove();
        }

        if (this.modalAnimationEnd) {
            this.modalAnimationEnd.subscribe(() => {
                this.element.nativeElement.remove();
            });
        } else {
            this.element.nativeElement.remove();
        }
    }

    @HostBinding('class.dark') get mode() {
        return this.themeService.isDarkMode;
    }
}
