import {HttpClient} from '@angular/common/http';
import {Injectable, inject} from '@angular/core';
import {CommonResponse} from '@core/models/common/common-response';
import {ConfigResponse} from '@core/models/user/config-response';
import {UserProfileResponse} from '@core/models/user/user-profile-response';
import {environment} from '@env/environment';
import {BehaviorSubject, Observable} from 'rxjs';
import {AuthService} from '@core/services';
import {ModalService} from '@core/services/modal/modal.service';
import {LoginComponent} from '@pages/auth/login/login.component';
import {storage} from '@core/utils';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    http = inject(HttpClient);
    authService = inject(AuthService);

    private userBaseUrl: string;
    private userConfigUrl: string;

    userToFollow$ = new BehaviorSubject<number | null>(storage.getItem('userToFollow') || null);
    constructor(private modalService: ModalService) {
        this.userBaseUrl = environment.apiUrl + '/user';
        this.userConfigUrl = environment.apiUrl + '/config';
        //his.userSubject = new BehaviorSubject<User>({ user: {}, token: '', refreshToken: ''});
    }

    get userToFollow(): number | null {
        return this.userToFollow$.getValue();
    }

    followUser(userId: number, userDetail: {fullName: string; photo: string}): Observable<CommonResponse> {
        const isSkipped = this.authService.isSkipped$.getValue();
        if (isSkipped) {
            this.modalService.open(LoginComponent, {data: {showFollow: true, userDetail: userDetail}});
            this.setUserToFollow(userId);
            return new Observable<CommonResponse>();
        }

        const reqBody = {
            followingId: userId,
        };
        return this.http.post<CommonResponse>(this.userBaseUrl + '/follow', reqBody);
    }

    getCurrentUserDetail(): Observable<UserProfileResponse> {
        return this.http.get<UserProfileResponse>(this.userBaseUrl);
    }

    getConfig(countryCode: string, languageCode: string): Observable<ConfigResponse> {
        const reqParams = {
            'x-req-langCode': languageCode,
        };

        const options = {headers: reqParams};
        return this.http.get<ConfigResponse>(this.userConfigUrl, options);
    }

    setUserToFollow(userId: number) {
        this.userToFollow$.next(userId);
        storage.setItem('userToFollow', userId);
    }

    updateShowInLeaderboard(showInLeaderboard: boolean): Observable<CommonResponse> {
        const reqBody = {
            isShowInLeaderboard: showInLeaderboard,
        };
        return this.http.put<CommonResponse>(this.userBaseUrl, reqBody);
    }
}
