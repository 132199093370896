import {Component} from '@angular/core';
import {ModalService} from '@core/services/modal/modal.service';

@Component({
    selector: 'app-mt-modal-close-button',
    standalone: true,
    imports: [],
    templateUrl: './modal-close-button.component.html',
    styleUrl: './modal-close-button.component.scss',
})
export class ModalCloseButtonComponent {
    constructor(private modalService: ModalService) {}

    onClick() {
        this.modalService.close({data: 'test'});
    }
}
