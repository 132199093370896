<div
    @fadeToUp
    class="flex items-center  w-full max-w-xs p-4 bg-white border border-gray-200 dark:bg-gray-800 dark:border-gray-700 rounded-lg shadow-lg  absolute gap-2 {{
        positionClass
    }}  z-50"
>
    <img src="assets/images/shared/icons/toast-icons/{{ icon }}.svg" alt="Error icon" class="size-8" />

    <div class="flex-1 items-center break-words">
        <!-- Your toast content goes here -->
        <!-- Example: -->
        <p class="text-gray-700 dark:text-gray-400 text-sm font-medium">{{ this.options.message }}</p>
    </div>

    <button
        (click)="closeToast()"
        type="button"
        class="-my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 flex items-center justify-center h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
    >
        <span class="sr-only">Close</span>
        <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
            <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
            />
        </svg>
    </button>
</div>
