import {Component, inject} from '@angular/core';
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {ModalCloseButtonComponent} from '@core/components/ui-components/modal-close-button/modal-close-button.component';
import {AuthService} from '@core/services';
import {MailSentModalComponent} from '@pages/auth/mail-sent-modal/mail-sent-modal.component';
import {ModalService} from '@core/services/modal/modal.service';
import {CommonResponse} from '@core/models/common/common-response';
import {HttpErrorResponse, HttpStatusCode} from '@angular/common/http';
import {CommonModule} from '@angular/common';
import {AlertBoxComponent} from '@shared/components/alert-box/alert-box.component';
import {CommonError} from '@core/models/common/common-error';
import {translate, TranslocoPipe} from '@ngneat/transloco';
import {ConstantService} from '@core/services/common/constant.service';
import {ToastService} from '@core/services/ui/toast/toast.service';
import {ToastOptions} from '@shared/components/toast/ToastOptions';
import {i18nConstants} from '@core/constants/i18n-constants';
import validateEmail from '@core/utils/helpers/email-validator';

@Component({
    selector: 'app-forgot-password-modal',
    standalone: true,
    imports: [
        ModalCloseButtonComponent,
        MailSentModalComponent,
        ReactiveFormsModule,
        CommonModule,
        AlertBoxComponent,
        TranslocoPipe,
    ],
    templateUrl: './forgot-password-modal.component.html',
    styleUrl: './forgot-password-modal.component.scss',
})
export class ForgotPasswordModalComponent {
    authService = inject(AuthService);
    modalService = inject(ModalService);
    constantService = inject(ConstantService);
    toastService = inject(ToastService);

    error: CommonError | undefined;

    constructor() {}

    loginForm = new FormGroup({
        email: new FormControl<string>('', {nonNullable: true, validators: [Validators.email, Validators.required]}),
    });

    onSubmit() {
        const errorMessage: ToastOptions = {
            message: translate(i18nConstants.EmailEntry_Provide_Valid_Email),
            type: 'error',
            duration: 5000,
            position: 'bottom-center',
            closeable: true,
        };

        if (this.loginForm.invalid) {
            this.toastService.openToast(errorMessage);
            return;
        }
        if (!validateEmail(this.loginForm.value.email!)) {
            this.toastService.openToast(errorMessage);
            return;
        }
        this.authService.forgotPassword(this.loginForm.value.email!).subscribe(
            (data: CommonResponse) => {
                if (data.success) {
                    this.modalService.close();
                    this.modalService.closeAndOpen(MailSentModalComponent);
                } else {
                    this.error = {
                        title: 'ERROR',
                        description: 'UNDEFINED ERROR',
                    };
                }
            },
            (error: HttpErrorResponse) => {
                console.log(error);
                if (error.status === HttpStatusCode.BadRequest) {
                    if (error.error.details.message == 'NOT_FOUND_USER') {
                        this.error = {
                            title: 'ERROR',
                            description: 'NOT_FOUND_USER',
                        };
                    } else {
                        this.toastService.openToast(errorMessage);
                    }
                }
            },
        );
    }

    protected readonly MailSentModalComponent = MailSentModalComponent;
}
