import {inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '@env/environment';

@Injectable({
    providedIn: 'root',
})
export class ConversionService {
    http = inject(HttpClient);
    conversionUrl = '/panel-dashboard/conversion';

    constructor() {
        this.conversionUrl = environment.apiUrl + this.conversionUrl;
    }
    sendConversionData(data: string) {
        const body = {conversionData: data};
        return this.http.post<{isSaved: boolean}>(this.conversionUrl, body);
    }
}
