import {GoogleSigninButtonModule} from '@abacritt/angularx-social-login';
import {AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, inject, Output, Type} from '@angular/core';
import {ThemeService} from '@core/services';
import {ModalService} from '@core/services/modal/modal.service';
import {QrLoginComponent} from '@pages/auth/qr-login/qr-login.component';
import {MailLoginModalComponent} from '@pages/auth/mail-login-modal/mail-login-modal.component';
import {TranslocoPipe} from '@ngneat/transloco';
import {NgIf} from '@angular/common';
import {i18nConstants} from '@core/constants/i18n-constants';

@Component({
    selector: 'app-mt-login-button',
    standalone: true,
    imports: [GoogleSigninButtonModule, TranslocoPipe, NgIf],
    templateUrl: './login-button.component.html',
    styleUrl: './login-button.component.scss',
})
export class LoginButtonComponent implements AfterViewChecked {
    themeService = inject(ThemeService);
    showContent: boolean;
    @Output() fbLogin = new EventEmitter();

    constructor(
        private modalService: ModalService,
        private cdr: ChangeDetectorRef,
    ) {
        this.showContent = false;
    }

    ngAfterViewChecked(): void {
        this.showContent = true;
        this.cdr.detectChanges();
    }

    openComponent(component: Type<unknown>) {
        this.modalService.close();

        this.modalService.open(component);
    }

    onFbLogin() {
        this.fbLogin.emit();
    }

    protected readonly QrLoginComponent = QrLoginComponent;
    protected readonly MailLoginModalComponent = MailLoginModalComponent;
    protected readonly i18nConstants = i18nConstants;
}
