<div class="absolute z-50 end-8 top-6">
    <button
        aria-label="Close modal"
        (click)="onClick()"
        class="rounded-full transition duration-300 ease-in-out hover:bg-neutral-100 p-2 dark:hover:bg-gray-600 focus:outline-none"
    >
        <svg
            class="w-6 h-6 text-black dark:text-white"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
        </svg>
    </button>
</div>
