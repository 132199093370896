import {Component, ComponentRef, OnInit} from '@angular/core';
import {ToastOptions} from '@shared/components/toast/ToastOptions';
import {ToastService} from '@core/services/ui/toast/toast.service';
import {animate, style, transition, trigger} from '@angular/animations';
import {ThemeService} from '@core/services';

@Component({
    selector: 'app-toast',
    standalone: true,
    imports: [],
    animations: [
        trigger('fadeToUp', [transition(':enter', [style({opacity: 0}), animate('300ms', style({opacity: 1}))])]),
    ],
    templateUrl: './toast.component.html',
    styleUrls: ['./toast.component.scss'],
})
export class ToastComponent implements OnInit {
    options!: ToastOptions;
    positionClass!: string;
    componentRef!: ComponentRef<ToastComponent>;
    icon!: string;
    constructor(
        private toastService: ToastService,
        private themeService: ThemeService,
    ) {}
    ngOnInit() {
        this.setPosition();
        if (this.themeService.isDarkMode) {
            this.componentRef.location.nativeElement.classList.add('dark');
        }
        this.setType();
    }

    setType() {
        switch (this.options.type) {
            case 'success':
                this.icon = 'check-icon';
                break;
            case 'error':
                this.icon = 'error-icon';
                break;
            case 'warning':
                this.icon = 'warning-icon';
                break;
        }
    }

    setPosition() {
        switch (this.options.position) {
            case 'top-left':
                this.positionClass = 'top-5 left-5';
                break;
            case 'top-right':
                this.positionClass = 'top-5 right-5';
                break;
            case 'top-center':
                this.positionClass = 'top-5 left-1/2 transform -translate-x-1/2';
                break;
            case 'bottom-left':
                this.positionClass = 'bottom-5 left-5';
                break;
            case 'bottom-right':
                this.positionClass = 'bottom-5 right-5';
                break;
            case 'bottom-center':
                this.positionClass = 'bottom-5 left-1/2 transform -translate-x-1/2';
                break;
            default:
                this.positionClass = 'top-5 right-5';
                break;
        }
    }

    closeToast() {
        this.toastService.closeToast(this.componentRef);
    }

    setComponentRef(componentRef: ComponentRef<ToastComponent>) {
        this.componentRef = componentRef;
    }
}
