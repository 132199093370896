import {Component} from '@angular/core';
import {ModalCloseButtonComponent} from '@core/components/ui-components/modal-close-button/modal-close-button.component';
import {i18nConstants} from '@core/constants/i18n-constants';
import {TranslocoPipe} from '@ngneat/transloco';

@Component({
    selector: 'app-mail-sent-modal',
    standalone: true,
    imports: [ModalCloseButtonComponent, TranslocoPipe],
    templateUrl: './mail-sent-modal.component.html',
    styleUrl: './mail-sent-modal.component.scss',
})
export class MailSentModalComponent {
    protected readonly i18nConstants = i18nConstants;
}
