import {Injectable, ComponentRef, ApplicationRef, createComponent, EnvironmentInjector} from '@angular/core';
import {ToastComponent} from '@shared/components/toast/toast.component';
import {ToastOptions} from '@shared/components/toast/ToastOptions';

@Injectable({
    providedIn: 'root',
})
export class ToastService {
    activeToasts: ComponentRef<ToastComponent>[] = [];
    maxToasts = 1;

    constructor(
        private appRef: ApplicationRef,
        private injector: EnvironmentInjector,
    ) {}

    openToast(options: ToastOptions) {
        const newComponent = createComponent(ToastComponent, {environmentInjector: this.injector});
        newComponent.instance.options = options;

        newComponent.instance.setComponentRef(newComponent);
        this.activeToasts.push(newComponent);
        if (this.activeToasts.length > this.maxToasts) {
            this.closeToast(this.activeToasts[0]);
        }
        this.appRef.attachView(newComponent.hostView);

        document.body.appendChild(newComponent.location.nativeElement);

        if (options?.duration) {
            setTimeout(() => {
                this.closeToast(newComponent);
            }, options.duration);
        }
    }

    closeToast(component: ComponentRef<ToastComponent>) {
        const index = this.activeToasts.indexOf(component);
        if (index !== -1) {
            this.activeToasts.splice(index, 1);
        }
        const toastElement = component.location.nativeElement as HTMLElement;

        this.appRef.detachView(component.hostView);
        component.destroy();
        if (toastElement.parentNode) {
            toastElement.parentNode.removeChild(toastElement);
        }
    }
}
