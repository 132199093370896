<!--<button
    class="select-none border-1 border-[#151b26] rounded-lg w-full max-w-full p-2.5 bg-[#151B26] dark:bg-[#ffffff] dark:border-2 dark:border-[#ffffff] hover:bg-[#222B3C] hover:border-[#222B3C] transform transition-colors duration-200 dark:hover:bg-gray-200"
>
    <div class="flex flex-row items-center justify-center text-[#ffffff] dark:text-[#000000]">
        <img
            src="assets/images/shared/login/{{ themeService.isDarkMode ? 'apple-login-dark.svg' : 'apple-login.svg' }}"
            alt="Apple Login"
        />
        <p class="pl-2 text-sm">{{ 'AuthOptions_Apple' | transloco }}</p>
    </div>
</button>
-->
<!--<button (click)="continueWithGoogle()"
    class="select-none mt-4 border-2 rounded-lg w-full p-2.5 bg-[#ffffff] dark:bg-[#151B26] dark:border-[#151B26] hover:bg-gray-50 transform transition-colors duration-200 ease-in-out dark:hover:bg-[#222B3C] dark:hover:border-[#222B3C]"
>
    <div class="flex flex-row items-center justify-center text-[#000000] dark:text-[#ffffff]">
        <img src="assets/images/shared/login/google-login.svg" alt="Google Login" />
        <p class="pl-2 text-sm">Continue With Google</p>
    </div>
</button>-->

<div *ngIf="showContent" class="mt-4">
    <asl-google-signin-button
        type="standard"
        theme="outline"
        size="large"
        text="continue_with"
        [width]="350"
        logo_alignment="center"
    ></asl-google-signin-button>
</div>

<button
    (click)="onFbLogin()"
    class="select-none mt-4 border-2 rounded-lg w-full max-w-full p-2.5 bg-[#ffffff] dark:bg-[#151B26] dark:border-[#151B26] hover:bg-gray-50 transform transition-colors duration-200 dark:hover:bg-[#222B3C] dark:hover:border-[#222B3C]"
>
    <div class="flex flex-row items-center justify-center text-[#000000] dark:text-[#ffffff]">
        <img src="assets/images/shared/login/fa-icon.png" class="w-5 h-5" alt="Facebook Login" />
        <p class="pl-2 text-sm">{{ i18nConstants.Facebook_Login | transloco }}</p>
    </div>
</button>

<button
    (click)="openComponent(MailLoginModalComponent)"
    class="select-none mt-4 border-2 rounded-lg w-full max-w-full p-2.5 bg-[#ffffff] dark:bg-[#151B26] dark:border-[#151B26] hover:bg-gray-50 transform transition-colors duration-200 dark:hover:bg-[#222B3C] dark:hover:border-[#222B3C]"
>
    <div class="flex flex-row items-center justify-center text-[#000000] dark:text-[#ffffff]">
        <img
            src="assets/images/shared/login/{{ themeService.isDarkMode ? 'mail-login-dark.svg' : 'mail-login.svg' }}"
            alt="Mail Login"
        />
        <p class="pl-2 text-sm">{{ 'AuthOptions_Email' | transloco }}</p>
    </div>
</button>
<!--
<button
    (click)="openComponent(QrLoginComponent)"
    class="select-none mt-4 border-2 rounded-lg w-full max-w-full p-2.5 bg-[#ffffff] dark:bg-[#151B26] dark:border-[#151B26] hover:bg-gray-50 transform transition-colors duration-200 dark:hover:bg-[#222B3C] dark:hover:border-[#222B3C]"
>
    <div class="flex flex-row items-center justify-center text-[#000000] dark:text-[#ffffff]">
        <img
            src="assets/images/shared/login/{{ themeService.isDarkMode ? 'qr-login-dark.svg' : 'qr-login.svg' }}"
            alt="QR Login"
        />
        <p class="pl-2 text-sm">{{ 'AuthOptions_QR' | transloco }}</p>
    </div>
</button>
-->
<!--
<div class="relative flex py-5 items-center select-none">
    <div class="flex-grow border-t border-neutral-200"></div>
    <span class="flex-shrink mx-1 text-gray-400">{{ 'EmailEntry_Or' | transloco }}</span>
    <div class="flex-grow border-t border-neutral-200"></div>
</div>
-->
<!--
<button
    class="select-none border-2 rounded-lg w-full max-w-full p-2.5 bg-[#ffffff] dark:bg-[#151B26] dark:border-[#151B26] hover:bg-gray-50 transform transition-colors duration-200 dark:hover:bg-[#222B3C] dark:hover:border-[#222B3C]"
>
    <div class="flex flex-row items-center justify-center text-[#000000] dark:text-[#ffffff]">
        <img
            src="assets/images/shared/login/{{
                themeService.isDarkMode ? 'mobile-login-dark.svg' : 'mobile-login.svg'
            }}"
            alt="Mobile Login"
        />
        <p class="pl-2 text-sm">{{ 'AuthOptions_Phone' | transloco }}</p>
    </div>
</button>
-->
