import {Subject} from 'rxjs';

export interface Options {
    modal?: {
        enter?: string;
        leave?: string;
    };
    size?: {
        height?: number;
        width?: number;
        maxWidth?: number;
        maxHeight?: number;
    };
    position?: ModalPositions;
    backdrop?: boolean;

    data?: {
        [key: string]: unknown;
    };
}

export const changeModalPosition = (position: ModalPositions | undefined) => {
    switch (position) {
        case 'center':
            return {left: 50, top: 50};
        case 'top':
            return {left: 50, top: 0};
        case 'bottom':
            return {left: 50, top: 100};
        case 'left':
            return {left: 0, top: 50};
        case 'right':
            return {left: 100, top: 50};
        case 'top-left':
            return {left: 0, top: 0};
        case 'top-right':
            return {left: 100, top: 0};
        case 'bottom-left':
            return {left: 0, top: 100};
        case 'bottom-right':
            return {left: 100, top: 100};
        case 'top-center':
            return {left: 50, top: 0};
        default:
            return {left: 50, top: 50};
    }
};

export interface ModalPositionIndicator {
    left: number;
    top: number;
}

export type ModalPositions =
    | 'center'
    | 'top'
    | 'bottom'
    | 'left'
    | 'right'
    | 'top-left'
    | 'top-right'
    | 'bottom-left'
    | 'bottom-right'
    | 'top-center';

export interface SubjectModal {
    subject: Subject<any>;
}
