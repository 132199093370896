export class i18nConstants {
    static readonly IAP_SUCCEED_TITLE = 'iap_succeed_title';
    static readonly IAP_SUCCEED_DESC = 'iap_succeed_desc';
    static readonly ForgotPassword_Enter_Email_For_Resetting_Pass = 'ForgotPassword_Enter_Email_For_Resetting_Pass';
    static readonly Login_Forgot_Password = 'Login_Forgot_Password';
    static readonly Login_Email = 'Login_Email';
    static readonly Login_Login = 'Login_Login';
    static readonly AuthOptions_SignUp = 'AuthOptions_SignUp';
    static readonly Login_Password = 'Login_Password';
    static readonly Dropdown_Live = 'Dropdown_Live';
    static readonly Dropdown_AboutUs = 'Dropdown_AboutUs';
    static readonly Dropdown_ToS = 'Dropdown_ToS';
    static readonly Dropdown_PrivacyPolicy = 'Dropdown_PrivacyPolicy';
    static readonly Dropdown_CommunityGuideline = 'Dropdown_CommunityGuideline';
    static readonly Open_Main_Menu = 'Open_Main_Menu';
    static readonly Web_Spin = 'Web_Spin';
    static readonly Download_App = 'Download_App';
    static readonly Buy_Crystal = 'Buy_Crystal';
    static readonly Buy_Crystal_Title = 'Get_Crystals_Title';
    static readonly Web_Spin_Title = 'Web_Spin_Title';
    static readonly Play_Plinko = 'Play_Plinko';
    static readonly Sign_Up = 'Sign Up';
    static readonly Sign_Up_Page_Title = 'Sign_Up_Page_Title';
    static readonly Your_Email_Address = 'Your_Email_Address';
    static readonly Follow_Register_Steps = 'Follow_Register_Steps';
    static readonly Mute_Participant = 'Mute_Participant';
    static readonly Unmute_Participant = 'Unmute_Participant';
    static readonly StreamVC_UserMuted = 'StreamVC_UserMuted';
    static readonly StreamVC_UserUnmuted = 'StreamVC_UserUnmuted';
    static readonly WatchPartyVC_YouAreUnmuted = 'WatchPartyVC_YouAreUnmuted';
    static readonly WatchPartyVC_YouAreMuted = 'WatchPartyVC_YouAreMuted';
    static readonly Mute_Me = 'Mute_Me';
    static readonly Unmute_Me = 'Unmute_Me';
    static readonly EmailEntry_Provide_Valid_Email = 'EmailEntry_Provide_Valid_Email';
    static readonly ForgotPassword_Mail_Sent = 'ForgotPassword_Mail_Sent';
    static readonly ForgotPassword_Check_MailBox = 'ForgotPassword_Check_MailBox';
    static readonly PasswordEntry_Provide_Valid_Password = 'PasswordEntry_Provide_Valid_Password';
    static readonly Get_App_Title = 'Get_App_Title';
    static readonly Get_App_Footer = 'Get_App_Footer';
    static readonly Wheel_Spin = 'Wheel_Spin';
    static readonly Wheel_Spin_Result = 'Wheel_Spin_Result';
    static readonly Not_Registered_Yet = 'Not_Registered_Yet';
    static readonly Facebook_Login = 'Facebook_Login';
    static readonly Password_Requirements = 'Password_Requirements';
}
