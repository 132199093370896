import {Injectable} from '@angular/core';

import {i18nConstants} from '../../constants/i18n-constants';

@Injectable({
    providedIn: 'root',
})
export class ConstantService {
    public i18n_constants = i18nConstants;
}
