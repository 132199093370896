<!-- Main modal -->
<div class="relative p-4 w-full max-w-md max-h-full mx-auto">
    <app-mt-modal-close-button></app-mt-modal-close-button>
    <!-- Modal content -->
    <div class="relative flex items-center justify-center flex-col bg-white rounded-lg shadow dark:bg-gray-700">
        <!-- Modal header -->
        <div
            class="flex flex-col items-center justify-center px-4 py-8 md:pt-10 md:pb-8 rounded-t dark:border-gray-600"
        >
            <h3 class="text-lg font-semibold text-black dark:text-white">
                {{ constantService.i18n_constants.Login_Forgot_Password | transloco }}
            </h3>
            <p class="py-2 px-4 text-center font-normal text-xs dark:text-neutral-300">
                {{ constantService.i18n_constants.ForgotPassword_Enter_Email_For_Resetting_Pass | transloco }}
            </p>
        </div>
        <!-- Modal body -->
        <div class="px-4 pb-4 md:px-8 md:pb-8 w-full">
            <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                <div class="flex flex-col w-full items-center justify-center">
                    <div class="mb-16 w-full">
                        <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{{
                            constantService.i18n_constants.Login_Email | transloco
                        }}</label>
                        <input
                            type="email"
                            id="email"
                            formControlName="email"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                            placeholder="{{ constantService.i18n_constants.Your_Email_Address | transloco }}"
                            required
                        />
                    </div>
                    <div class="w-full">
                        <button
                            type="submit"
                            class="w-full py-2.5 bg-black text-white text-sm font-medium rounded-lg hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 dark:bg-white dark:text-black dark:hover:bg-neutral-200 dark:focus:ring-primary-500 dark:focus:ring-offset-gray-900 dark:focus:ring-offset-2"
                        >
                            {{ 'ForgotPassword_Next' | transloco }}
                        </button>
                    </div>
                </div>
            </form>
        </div>
        <div *ngIf="error" class="px-4 pb-4 md:px-8 md:pb-8 w-full">
            <div class="flex flex-col w-full items-center justify-center mb-4">
                <app-alert-box class="w-full" [description]="error.description | transloco"></app-alert-box>
            </div>
        </div>
    </div>
</div>
